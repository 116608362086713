import { useMemo } from 'react';

import { RoutersEnum, RoutersFullpathEnum } from 'routers';
import InitializationConsumer from 'shared/context/initialization';
import { SUBSCRIPTION_STATUSES } from 'shared/enums/subscriptionStatus';
import AuthConsumer from 'shared/guards/auth.guard';
import { SUBSCRIPTIONS_PLANS } from 'shared/types/subscriptionPlansTypes';

export const useGetNavigatePathDueToPlan = (): {
	navigatePath: RoutersEnum | RoutersFullpathEnum | '';
} => {
	const { user } = AuthConsumer();
	const { initialization } = InitializationConsumer();

	const navigatePath = useMemo(() => {
		if (initialization && initialization?.subscriptionStatus !== SUBSCRIPTION_STATUSES.ACTIVE) {
			return RoutersFullpathEnum.inactive_subscription;
		}

		if (initialization && initialization?.subscriptionPlans.includes(SUBSCRIPTIONS_PLANS.PLAN)) {
			return RoutersEnum.plan;
		}

		if (initialization && initialization?.subscriptionPlans.includes(SUBSCRIPTIONS_PLANS.BUILD)) {
			return RoutersEnum.project_portfolio_projects;
		}

		if (initialization && initialization?.subscriptionPlans.includes(SUBSCRIPTIONS_PLANS.GROW)) {
			return RoutersEnum.optimization;
		}

		if (
			initialization &&
			initialization?.subscriptionPlans.includes(SUBSCRIPTIONS_PLANS.ENTERPRISE)
		) {
			return RoutersEnum.home;
		}

		return '';
	}, [user, initialization?.subscriptionPlans]);

	return { navigatePath };
};
