import { FC } from 'react';

import { Button, Flex } from 'antd';
import { FallbackProps } from 'react-error-boundary';
import { useNavigate, useParams } from 'react-router-dom';
import { concatUrl } from 'routers';
import AuthConsumer from 'shared/guards/auth.guard';
import { useGetNavigatePathDueToPlan } from 'shared/hooks/useGetNavigatePathDueToPlan';

import styles from './ErrorPage.module.scss';

const ErrorPage: FC<FallbackProps> = ({ error, resetErrorBoundary }): JSX.Element => {
	const { authed } = AuthConsumer();
	const { engId = '' } = useParams();
	const navigate = useNavigate();

	const { navigatePath } = useGetNavigatePathDueToPlan();

	const handleReloadPage = (): void => {
		resetErrorBoundary();
		window.location.reload();
	};

	const handleNavigateToHome = (): void => {
		navigate(concatUrl([engId, navigatePath], true), {
			replace: true,
		});
	};

	const stringError = typeof error === 'string' ? error : null;
	return (
		<Flex className={styles.container} justify="center" align="center">
			<Flex vertical gap={24}>
				<h1>{error?.response?.data?.message || error?.message || stringError || 'Error!'}</h1>
				<Flex gap={16}>
					<Button type="primary" onClick={handleReloadPage}>
						Reload Page
					</Button>
					{authed && engId && (
						<Button type="primary" onClick={handleNavigateToHome}>
							Go Home Page
						</Button>
					)}
				</Flex>
			</Flex>
		</Flex>
	);
};

export default ErrorPage;
