import { TourProps } from 'antd';
import { ONBOARDING_GUIDE_MODULES } from 'shared/enums/onboardingGuideModules';
import { TRefsObject } from 'shared/types/onboardingTypes';

import { TOnboardingGuideModule } from '../onboardingContext';
import { useActionPlansOnboardingConfig } from '../onboardingModules/useActionPlansOnboardingConfig';
import { useDiagramsPartOneOnboardingConfig } from '../onboardingModules/useDiagramsPartOneOnboardingConfig';
import { useDiagramsPartTwoOnboardingConfig } from '../onboardingModules/useDiagramsPartTwoOnboardingConfig';
import { useFileExplorerOnboardingConfig } from '../onboardingModules/useFileExplorerOnboardingConfig';
import { useForecastPartOneOnboardingConfig } from '../onboardingModules/useForecastPartOneOnboardingConfig';
import { useForecastPartTwoOnboardingConfig } from '../onboardingModules/useForecastPartTwoOnboardingConfig';
import { useHomeWalkthroughOnboardingConfig } from '../onboardingModules/useHomeWalkthroughOnboardingConfig';
import { useIndustryReportsOnboardingConfig } from '../onboardingModules/useIndustryReportsOnboardingConfig';
import { useOperatingModelOnboardingConfig } from '../onboardingModules/useOperatingModelOnboardingConfig';
import { useOptimizationOnboardingConfig } from '../onboardingModules/useOptimizationOnboardingConfig';
import { usePlansPartOneOnboardingConfig } from '../onboardingModules/usePlansPartOneOnboardingConfig';
import { usePlansPartTwoOnboardingConfig } from '../onboardingModules/usePlansPartTwoOnboardingConfig';
import { useProcessReportsOnboardingConfig } from '../onboardingModules/useProcessReportsOnboardingConfig';
import { useProjectsPartOneOnboardingConfig } from '../onboardingModules/useProjectsPartOneOnboardingConfig';
import { useProjectsPartTwoOnboardingConfig } from '../onboardingModules/useProjectsPartTwoOnboardingConfig';
import { useRetrospectivesOnboardingConfig } from '../onboardingModules/useRetrospectivesOnboardingConfig';
import { useStoryboardPartOneOnboardingConfig } from '../onboardingModules/useStoryboardPartOneOnboardingConfig';
import { useStoryboardPartTwoOnboardingConfig } from '../onboardingModules/useStoryboardPartTwoOnboardingConfig';
import { useWorkspaceOnboardingConfig } from '../onboardingModules/useWorkspaceOnboardingConfig';

export type TOnboardingConfig = {
	steps: TourProps['steps'];
	stepsWithAction?: number[];
	stepsWithAllowedInteractions?: number[];
};

const useOnboardingConfigMap = (
	refs: TRefsObject,
): Partial<Record<TOnboardingGuideModule, TOnboardingConfig>> => {
	return {
		[ONBOARDING_GUIDE_MODULES.HOME_WALKTHROUGH]: useHomeWalkthroughOnboardingConfig(refs),
		[ONBOARDING_GUIDE_MODULES.WORKSPACE]: useWorkspaceOnboardingConfig(refs),
		[ONBOARDING_GUIDE_MODULES.STORYBOARD_1]: useStoryboardPartOneOnboardingConfig(refs),
		[ONBOARDING_GUIDE_MODULES.STORYBOARD_2]: useStoryboardPartTwoOnboardingConfig(refs),
		[ONBOARDING_GUIDE_MODULES.PLANS_1]: usePlansPartOneOnboardingConfig(refs),
		[ONBOARDING_GUIDE_MODULES.PLANS_2]: usePlansPartTwoOnboardingConfig(refs),
		[ONBOARDING_GUIDE_MODULES.FORECAST_1]: useForecastPartOneOnboardingConfig(refs),
		[ONBOARDING_GUIDE_MODULES.FORECAST_2]: useForecastPartTwoOnboardingConfig(refs),
		[ONBOARDING_GUIDE_MODULES.OPERATING_MODEL]: useOperatingModelOnboardingConfig(refs),
		[ONBOARDING_GUIDE_MODULES.DIAGRAMS_1]: useDiagramsPartOneOnboardingConfig(refs),
		[ONBOARDING_GUIDE_MODULES.DIAGRAMS_2]: useDiagramsPartTwoOnboardingConfig(refs),
		[ONBOARDING_GUIDE_MODULES.OPTIMIZATION]: useOptimizationOnboardingConfig(refs),
		[ONBOARDING_GUIDE_MODULES.RETROSPECTIVES]: useRetrospectivesOnboardingConfig(refs),
		[ONBOARDING_GUIDE_MODULES.PROJECTS_1]: useProjectsPartOneOnboardingConfig(refs),
		[ONBOARDING_GUIDE_MODULES.PROJECTS_2]: useProjectsPartTwoOnboardingConfig(refs),
		[ONBOARDING_GUIDE_MODULES.FILE_EXPLORER]: useFileExplorerOnboardingConfig(refs),
		[ONBOARDING_GUIDE_MODULES.INDUSTRY_REPORTS]: useIndustryReportsOnboardingConfig(refs),
		[ONBOARDING_GUIDE_MODULES.PROCESS_REPORTS]: useProcessReportsOnboardingConfig(refs),
		[ONBOARDING_GUIDE_MODULES.ACTION_PLANS]: useActionPlansOnboardingConfig(refs),
	};
};

export default useOnboardingConfigMap;
