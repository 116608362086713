import { useMemo } from 'react';

import { TourProps } from 'antd';
import { ONBOARDING_GUIDE_MODULES } from 'shared/enums/onboardingGuideModules';
import { TRefsObject } from 'shared/types/onboardingTypes';

import { TOnboardingConfig } from '../hooks/useOnboardingConfigMap';

export const useProjectsPartTwoOnboardingConfig = (refs: TRefsObject): TOnboardingConfig => {
	return useMemo(() => {
		const steps: TourProps['steps'] = [
			{
				title: 'Navigation',
				placement: 'bottom',
				description: (
					<p>
						Each tab supports a project management function (project delivery, resources, risks,
						work management and work products).
					</p>
				),
			},
			{
				title: 'Action Button',
				placement: 'left',
				description: <p>Manage the number of functions (tabs) visible for the project.</p>,
			},
			{
				title: 'Timeline',
				placement: 'topRight',
				description: <p>Timeline will be visible here when activities have start and end dates.</p>,
			},
		];

		const mappedSteps: TourProps['steps'] = steps
			.map((step, index) => ({
				target: refs?.get(`${ONBOARDING_GUIDE_MODULES.PROJECTS_2}-${index + 1}`)?.current,
				...step,
				prevButtonProps: {
					children: 'Back',
				},
			}))
			.filter((step) => !!step.target);

		return { steps: mappedSteps, stepsWithAction: [1] };
	}, [refs]);
};
