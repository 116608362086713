import { useMemo } from 'react';

import { TourProps } from 'antd';
import { ONBOARDING_GUIDE_MODULES } from 'shared/enums/onboardingGuideModules';
import { TRefsObject } from 'shared/types/onboardingTypes';

import { TOnboardingConfig } from '../hooks/useOnboardingConfigMap';

export const useWorkspaceOnboardingConfig = (refs: TRefsObject): TOnboardingConfig => {
	return useMemo(() => {
		const steps: TourProps['steps'] = [
			{
				title: 'Engagements',
				description: <p>Switch between workspaces (engagements).</p>,
			},
			{
				title: 'Modules',
				placement: 'right',
				description: <p>Active modules are highlighted in green.</p>,
			},
			{
				title: 'Configure',
				placement: 'right',
				description: (
					<p>
						Adjust the configuration of the side menu. Hide items, change names, and arrange as
						needed.
					</p>
				),
			},
			{
				title: 'Side Menu',
				placement: 'topRight',
				description: <p>Expand or collapse the side menu.</p>,
			},
		];

		const mappedSteps: TourProps['steps'] = steps
			.map((step, index) => ({
				target: refs?.get(`${ONBOARDING_GUIDE_MODULES.WORKSPACE}-${index + 1}`)?.current,
				...step,
				prevButtonProps: {
					children: 'Back',
				},
			}))
			.filter((step) => !!step.target);

		return { steps: mappedSteps };
	}, [refs]);
};
