import { useMemo } from 'react';

import { TourProps } from 'antd';
import { ONBOARDING_GUIDE_MODULES } from 'shared/enums/onboardingGuideModules';
import { TRefsObject } from 'shared/types/onboardingTypes';

import { TOnboardingConfig } from '../hooks/useOnboardingConfigMap';

export const useDiagramsPartTwoOnboardingConfig = (refs: TRefsObject): TOnboardingConfig => {
	return useMemo(() => {
		const steps: TourProps['steps'] = [
			{
				title: 'Action Buttons',
				placement: 'right',
				description: <p>Copy, paste, undo, and redo.</p>,
			},
			{
				title: 'Action Button',
				placement: 'left',
				description: <p>Expand to full screen.</p>,
			},
			{
				title: 'Shapes',
				placement: 'right',
				description: <p>Add basic flow chart shapes.</p>,
			},
			// TODO: add "Google shapes step" (MG)
			{
				title: 'Shapes',
				placement: 'right',
				description: <p>Add other shapes and attach images.</p>,
			},

			{
				title: 'Settings',
				placement: 'bottomLeft',
				description: <p>Adjust settings for shapes and lines.</p>,
			},
		];

		const mappedSteps: TourProps['steps'] = steps
			.map((step, index) => ({
				target: refs?.get(`${ONBOARDING_GUIDE_MODULES.DIAGRAMS_2}-${index + 1}`)?.current,
				...step,
				prevButtonProps: {
					children: 'Back',
				},
			}))
			.filter((step) => !!step.target);

		return { steps: mappedSteps };
	}, [refs]);
};
