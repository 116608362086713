import { FunctionComponent, ReactElement } from 'react';

import { useLocation } from 'react-router-dom';

import { RoutersEnum } from '../routers';

import AuthLayout from './AuthLayout';

type TProps = {
	children: ReactElement;
};

const Layout: FunctionComponent<TProps> = (props): JSX.Element => {
	const { children } = props;

	const location = useLocation();

	const isAuthLayout = [
		RoutersEnum.login,
		RoutersEnum.registration,
		RoutersEnum.reset_password,
		RoutersEnum.change_password,
	].some((route) => location.pathname.includes(route));

	if (isAuthLayout) {
		return <AuthLayout>{children}</AuthLayout>;
	}

	return children;
};

export default Layout;
