import React, { FunctionComponent, ReactElement, useEffect } from 'react';

import { useQuery } from '@tanstack/react-query';
import { message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { RoutersEnum, RoutersFullpathEnum, concatUrl } from 'routers';
import {
	ReturnInitialization,
	getInitialization,
	useInitialization,
} from 'shared/api/initialization.service';
import { SUBSCRIPTION_STATUSES } from 'shared/enums/subscriptionStatus';
import AuthConsumer from 'shared/guards/auth.guard';
import { useIsIncludesInPathname } from 'shared/hooks/useIsIncludesInPathname';
import InitializationPropertiesModel from 'shared/models/initialization-properties.model';

const InitializationContext = React.createContext<ReturnInitialization>(undefined!);

export const InitializationProvider: FunctionComponent<{ children: ReactElement }> = ({
	children,
}) => {
	const initialization = useInitialization();
	const auth = AuthConsumer();
	const navigate = useNavigate();
	const isPublicRoute = useIsIncludesInPathname({ path: RoutersEnum.public });

	const { refetch, isLoading } = useQuery(['initialization'], getInitialization, {
		enabled: false,
		onSuccess: (data) => {
			initialization.setInitialization(new InitializationPropertiesModel(data));

			if (data?.subscriptionStatus !== SUBSCRIPTION_STATUSES.ACTIVE) {
				navigate(RoutersEnum.inactive_subscription);
				return;
			}

			if (
				location.pathname.includes(RoutersEnum.add_new_organization) &&
				!data?.organizationRequired
			) {
				const engId = auth?.user?.currentEngagement?.id;
				// @ts-ignore
				navigate(concatUrl([engId, RoutersFullpathEnum.users_management], true));
				return;
			}

			if (data?.organizationRequired) {
				navigate(RoutersEnum.add_new_organization);
				return;
			}
		},
	});

	useEffect(() => {
		if (isPublicRoute) return;

		(async (): Promise<void> => {
			if (!initialization.initialization && auth.authed) {
				try {
					await refetch();
				} catch (e) {
					message.error(e?.response?.data?.message || e.message || 'Error!');
				}
			}
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialization.initialization, auth.authed]);

	return (
		<InitializationContext.Provider
			value={{
				...initialization,
				initializationLoading: isLoading,
				refetchInitialization: refetch,
			}}
		>
			{children}
		</InitializationContext.Provider>
	);
};

export default function InitializationConsumer(): ReturnInitialization {
	const context = React.useContext(InitializationContext);
	if (context === undefined) {
		throw new Error('InitializationConsumer must be used within a InitializationProvider');
	}
	return context;
}
