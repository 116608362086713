import { useMemo } from 'react';

import { TourProps } from 'antd';
import { ONBOARDING_GUIDE_MODULES } from 'shared/enums/onboardingGuideModules';
import { TRefsObject } from 'shared/types/onboardingTypes';

import { TOnboardingConfig } from '../hooks/useOnboardingConfigMap';

export const useStoryboardPartTwoOnboardingConfig = (refs: TRefsObject): TOnboardingConfig => {
	return useMemo(() => {
		const steps: TourProps['steps'] = [
			{
				title: 'Content',
				placement: 'bottom',
				description: <p>Choose story blocks to create content within storyboards.</p>,
			},
		];

		const mappedSteps: TourProps['steps'] = steps.map((step, index) => ({
			target: refs?.get(`${ONBOARDING_GUIDE_MODULES.STORYBOARD_2}-${index + 1}`)?.current,
			...step,
			prevButtonProps: {
				children: 'Back',
			},
		}));

		return { steps: mappedSteps, stepsWithAction: [0], stepsWithAllowedInteractions: [0] };
	}, [refs]);
};
