import { FC, Suspense } from 'react';

import { Route } from 'react-router-dom';
import { RoutersEnum, TRoutes } from 'routers';
import { NoRequireAuth, RequireAccess, RequireAuth } from 'shared/guards/auth.guard';
import { useIsIncludesInPathname } from 'shared/hooks/useIsIncludesInPathname';
import PageLoader from 'shared/ui/PageLoader';

type TRoutesElementProps = {
	route: TRoutes;
};

const RoutesElement: FC<TRoutesElementProps> = ({ route }): JSX.Element => {
	const { isAuth, Component, isHasAccess } = route;
	const isPublicRoute = useIsIncludesInPathname({ path: RoutersEnum.public });

	if (isPublicRoute) {
		return <Component />;
	}

	if (isAuth) {
		return (
			<RequireAuth>
				{/* @ts-ignore */}
				<RequireAccess isHasAccess={isHasAccess}>
					<Suspense fallback={<PageLoader />}>
						<Component />
					</Suspense>
				</RequireAccess>
			</RequireAuth>
		);
	}
	if (isAuth === false) {
		return (
			<NoRequireAuth>
				<Suspense fallback={<PageLoader />}>
					<Component />
				</Suspense>
			</NoRequireAuth>
		);
	}
	return <Component />;
};

export const renderRoutes = (values: TRoutes[]): JSX.Element => {
	return (
		<>
			{values.map((route) => {
				const { path, children } = route;

				if (children?.length) {
					return (
						<Route path={path} key={path} element={<RoutesElement route={route} />}>
							{renderRoutes(children)}
						</Route>
					);
				}

				return <Route path={path} key={path} element={<RoutesElement route={route} />} />;
			})}
			;
		</>
	);
};
