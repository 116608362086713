import { useMemo } from 'react';

import { TourProps } from 'antd';
import { ONBOARDING_GUIDE_MODULES } from 'shared/enums/onboardingGuideModules';
import AuthConsumer from 'shared/guards/auth.guard';
import { TRefsObject } from 'shared/types/onboardingTypes';

import { TOnboardingConfig } from '../hooks/useOnboardingConfigMap';

export const useActionPlansOnboardingConfig = (refs: TRefsObject): TOnboardingConfig => {
	const { user } = AuthConsumer();

	return useMemo(() => {
		const isActionsAllowed = user?.isCanBeEditActionPlansPage;

		const steps: TourProps['steps'] = [
			{
				title: 'Button',
				placement: 'left',
				description: (
					<p>
						Leverage AI to create an action plan from plans, optimizations, and other data in Corvus
						Link.
					</p>
				),
			},
			{
				title: 'Action Button',
				placement: 'bottomRight',
				description: <p>Manage categories, tags, and archived action items.</p>,
			},
			{
				title: 'User Input',
				placement: 'right',
				description: <p>Create new action items here.</p>,
				target: isActionsAllowed
					? refs?.get(`${ONBOARDING_GUIDE_MODULES.ACTION_PLANS}-3`)?.current
					: null,
			},
			{
				title: 'Action Button',
				placement: 'bottomLeft',
				description: <p>Left-click on the plus sign to add a new action item.</p>,
				target: isActionsAllowed
					? refs?.get(`${ONBOARDING_GUIDE_MODULES.ACTION_PLANS}-4`)?.current
					: null,
			},
			{
				title: 'Action Button',
				placement: 'right',
				description: (
					<p>
						Right-click on the plus sign to open additional details, archive, or delete an action
						item.
					</p>
				),
				target: isActionsAllowed
					? refs?.get(`${ONBOARDING_GUIDE_MODULES.ACTION_PLANS}-5`)?.current
					: null,
			},
		];

		const mappedSteps: TourProps['steps'] = steps
			.map((step, index) => ({
				target: refs?.get(`${ONBOARDING_GUIDE_MODULES.ACTION_PLANS}-${index + 1}`)?.current,
				...step,
				prevButtonProps: {
					children: 'Back',
				},
			}))
			.filter((step) => !!step.target);

		return { steps: mappedSteps, stepsWithAction: isActionsAllowed ? [0, 1, 2, 3, 4] : undefined };
	}, [refs, user]);
};
