import { useMemo } from 'react';

import { TourProps } from 'antd';
import { ONBOARDING_GUIDE_MODULES } from 'shared/enums/onboardingGuideModules';
import AuthConsumer from 'shared/guards/auth.guard';
import { TRefsObject } from 'shared/types/onboardingTypes';

import { TOnboardingConfig } from '../hooks/useOnboardingConfigMap';

export const useOptimizationOnboardingConfig = (refs: TRefsObject): TOnboardingConfig => {
	const { user } = AuthConsumer();

	return useMemo(() => {
		const steps: TourProps['steps'] = [
			{
				title: 'Table',
				placement: 'top',
				description: <p>New optimizations are listed in this table.</p>,
			},
			{
				title: 'Button',
				placement: 'left',
				description: <p>Create an optimization or leverage AI support.</p>,
			},
			{
				title: 'Action Button',
				placement: 'left',
				description: <p>Edit optimization details and view reports/data.</p>,
				target: user?.isCanBeEditOptimizationPage
					? refs?.get(`${ONBOARDING_GUIDE_MODULES.OPTIMIZATION}-3`)?.current
					: null,
			},
		];

		const mappedSteps: TourProps['steps'] = steps
			.map((step, index) => ({
				target: refs?.get(`${ONBOARDING_GUIDE_MODULES.OPTIMIZATION}-${index + 1}`)?.current,
				...step,
				prevButtonProps: {
					children: 'Back',
				},
			}))
			.filter((step) => !!step.target);

		return { steps: mappedSteps };
	}, [refs, user]);
};
