import { useMemo } from 'react';

import { TourProps } from 'antd';
import { ONBOARDING_GUIDE_MODULES } from 'shared/enums/onboardingGuideModules';
import AuthConsumer from 'shared/guards/auth.guard';
import { TRefsObject } from 'shared/types/onboardingTypes';

import { TOnboardingConfig } from '../hooks/useOnboardingConfigMap';

export const useRetrospectivesOnboardingConfig = (refs: TRefsObject): TOnboardingConfig => {
	const { user } = AuthConsumer();

	return useMemo(() => {
		const steps: TourProps['steps'] = [
			{
				title: 'Table',
				placement: 'top',
				description: <p>All retrospectives are listed here.</p>,
			},
			{
				title: 'Button',
				placement: 'left',
				description: <p>Add new lessons learned or retrospectives.</p>,
			},
			{
				title: 'Action Button',
				placement: 'left',
				description: <p>Edit or delete retrospectives.</p>,
				target: user?.isCanBeEditRetrospectivesDashboardPage
					? refs?.get(`${ONBOARDING_GUIDE_MODULES.RETROSPECTIVES}-3`)?.current
					: null,
			},
			{
				title: 'Navigation',
				placement: 'bottom',
				description: <p>Review all solutions from retrospectives.</p>,
			},
			{
				title: 'Navigation',
				placement: 'right',
				description: <p>Dashboards from retrospectives data.</p>,
			},
		];

		const mappedSteps: TourProps['steps'] = steps
			.map((step, index) => ({
				target: refs?.get(`${ONBOARDING_GUIDE_MODULES.RETROSPECTIVES}-${index + 1}`)?.current,
				...step,
				prevButtonProps: {
					children: 'Back',
				},
			}))
			.filter((step) => !!step.target);

		return { steps: mappedSteps };
	}, [refs, user]);
};
